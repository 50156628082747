.root {
  height: calc(100% - 48px);
  :global {
    .content_header_card {
      height: 80px;
    }
    .content_header_card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }
    .cas_table .ant-table-thead {
    }
  }
}
