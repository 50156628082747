.root {
  height: calc(100% - 48px);
  :global {
    .search-card {
      height: 80px;
      border-radius: 8px;
    }
    .search-card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }
    // .editable-cell {
    //   position: relative;
    // }

    // .editable-cell-value-wrap {
    //   padding: 5px 12px;
    //   cursor: pointer;
    // }

    // .editable-row:hover .editable-cell-value-wrap {
    //   padding: 4px 11px;
    //   border: 1px solid #d9d9d9;
    //   border-radius: 2px;
    // }

    // [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    //   border: 1px solid #434343;
    // }
  }
}
