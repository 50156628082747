.root {
  height: calc(100% - 48px);
  :global {
    .search-card {
      height: 80px;
      border-radius: 8px;
    }
    .search-card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }
  }
}
