* {
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-width: 1280px;
  max-width: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
  background-color: #f7f7f7;
  overflow: auto;
}

.my-layout {
  height: 100%;
}

.ant-layout-content {
  height: calc(100vh - 48px);
}

.ant-drawer-content-wrapper .ant-drawer-body p {
  // height: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #313131;
}
.ant-drawer-content-wrapper .ant-drawer-body .desc {
  // height: 70px;
  font-size: 14px;
  width: 500px;
  font-weight: 400;
  color: #313131;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.ant-drawer-content-wrapper .ant-drawer-body .desc_text {
  display: inline;
  height: 70px;
  width: 500px;
  font-size: 14px;
  font-weight: 400;
  color: #313131;
  overflow: hidden;
  margin-bottom: 20px;
  // text-overflow: ellipsis;
}

.ant-drawer-content-wrapper .ant-drawer-body p span {
  display: inline-block;
  width: 100px;
  color: #535353;
}

.ant-drawer-content-wrapper .ant-col {
  min-width: 100%;
}

.ant-btn {
  border-radius: 6px;
}

.right_content {
  margin: 0px 24px;
  height: 100%;
}

.right_table {
  height: calc(100% - 150px);
}
.log_table {
  height: calc(100% - 180px);
}
.hei {
  height: 100%;
}

.ant-table {
  background: transparent;
}

.ant-table.ant-table-small {
  font-size: 12px;
}

.ant-breadcrumb {
  font-size: 12px;
  margin: 8px 0px;
}

.ant-tree-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px !important;
  display: inline-block !important;
}

.ant-tree .ant-tree-node-content-wrapper {
  width: 150px !important;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-radius: 10px;
}

.ant-modal-content .ant-table-wrapper {
  width: 984px;
  height: 560px;
}
.ant-modal-footer {
  text-align: center;
}

.ant-drawer .ant-drawer-content {
  border-radius: 10px 0px 0px 10px;
}

.ant-table-wrapper {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
}
.ant-table-pagination-right {
  padding-right: 32px;
}
.ant-table-thead > tr {
  width: 1655px;
  height: 32px;
  background: #eeeeee !important;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
}
.ant-table.ant-table-small .ant-table-thead > tr > th {
  height: 32px;
  padding: 0px;
  border-bottom: 0px;
  padding-left: 15px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  padding: 0px;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  height: 60px;
  color: #535353;
}

.drawer_body {
  margin-right: -24px !important;
}

.ant-message {
  width: 100%;
}

.ant-message-notice-content {
  height: 56px;
  padding: 16px 16px;
}

.ant-btn-default {
  width: 96px;
  font-size: 14px;
  font-weight: 400;
  border-color: #d8d8d8;
}
.ant-btn-primary {
  width: 96px;
  background-color: #006bb3;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border-color: #d8d8d8;
}

.ant-btn-link {
  font-size: 12px;
  padding-left: 0px;
}

.ant-table-thead > tr .ant-table-selection-column {
  font-size: 10px;
  transform: scale(1, 1);
}
.ant-table-thead > tr > th {
  font-size: 20px;
  background: #eeeeee !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  transform: scale(0.5, 0.5);
  transform-origin: left center;
}

.add-btn {
  margin: 16px 0px;
}

.ant-drawer-content-wrapper .ant-form-item {
  display: block;
}

.ant-drawer .ant-input-suffix {
  display: none;
}

.ant-form-item {
  margin-right: 20px;
}

.ant-drawer-content-wrapper .ant-form-item {
  margin-right: 32px;
}

.ant-card {
  border-radius: 8px;
}

.ant-drawer-content-wrapper .ant-form-item .ant-form-item-label {
  width: 100%;
  overflow: initial;
  text-align: left;
  color: #313131;
  font-size: 14px;
}

.ant-drawer-content-wrapper .ant-transfer-list {
  width: 264px;
  height: 264px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #d8d8d8;
}

.ant-drawer-content-wrapper .ant-spaces {
  float: right;
}

.ant-drawer-content-wrapper .ant-form {
  height: 100%;
}

.ant-drawer-content-wrapper .form-btn {
  // min-height: calc(100% - 50px);
  height: calc(100% - 25px);
  overflow: auto;
}

.ant-drawer-content-wrapper .ant-tabs {
  height: calc(100% - 160px);
}

.ant-drawer-content-wrapper .ant-tabs .ant-tabs-content {
  height: 100%;
}

.ant-drawer-content-wrapper .ant-tabs .ant-tabs-content .ant-tree {
  height: calc(100% - 40px);
}

.ant-drawer-content-wrapper .model-btn {
  text-align: center;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.content_card {
  height: 100%;
  overflow: hidden;
}

.scroll {
  overflow: auto;
  margin-right: 24px;
}

.form-search {
  display: flex;
  justify-content: space-between;
}
.log-search {
  display: flex;
  justify-content: space-between;

  // flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flex_end {
  position: fixed;
  bottom: 12px;
  right: 24px;
}

.btnColor {
  width: 96px;
  background-color: #006bb3;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border-color: #d8d8d8;
}

.is-true {
  height: 22px;
  width: 22px;
  color: #fff;
  border-radius: 50%;
  background-color: #026fb5;
  text-align: center;
  line-height: 22px;
}
.is-false {
  height: 22px;
  width: 22px;
  color: #fff;
  border-radius: 50%;
  background-color: #b9b9b9;
  text-align: center;
  line-height: 22px;
}

.zc {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #61d4ad;
  opacity: 1;
}
.ty {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #fe545f;
  opacity: 1;
}

.del {
  color: #787878 !important;
}
.edit {
  color: #026fb5 !important;
}
.start {
  color: #61d4ad !important;
}
.stop {
  color: #fe545f !important;
}

.form-flex {
  display: flex;
  flex-wrap: wrap;
}

.table_hei {
  height: calc(100% - 146px);
}

.comment-seach {
  display: flex;
  justify-content: space-between;
}

.comment-input {
  width: 238px;
  margin-right: 20px;
}

.space-heigth {
  margin: 16px 0;
}

.seachCo {
  margin-right: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.watch_marg {
  margin-top: 10px;
  margin-left: 10px;
}

.msg {
  position: relative;
  color: #313131;
  font-weight: 500;
  font-size: 14px;
}

.linear {
  width: 2px;
  height: 12px;
  // background-color: #0070b5;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: -13px;

  background: linear-gradient(180deg, #006bb3 0%, #16d8d8 100%);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0068b2;
}

.ant-menu-submenu-popup > .ant-menu {
  background: #48a1cc !important;
  color: #fff;

  a {
    color: #fff;
  }
  li:hover {
    background: rgb(145, 201, 224);
  }
  .ant-menu-item-selected {
    background: rgb(145, 201, 224);
  }
  i {
    color: #fff;
  }
}

.date_picker {
  width: 100%;
}

.tox-notifications-container {
  display: none;
}

.tox .tox-statusbar {
  display: none !important;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-style: dashed;
  border-color: transparent;
  border-width: 2px;
  background-color: rgba(193, 193, 193);
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(125, 125, 125);
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
.ant-tooltip {
  max-width: 300px;
}

// 媒体查询
@media screen and (max-width: 600px) {
  html,
  body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    min-width: 0px;
    max-width: 100%;
    height: 100%;
  }
}
