.root {
  height: calc(100% - 48px);
  :global {
    .ant-card ant-card-bordered {
      height: 100%;
    }
    .ant-card-body {
      height: 100%;
    }
    .ant-tabs-content {
      height: 100%;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 10px;
    }
    .log_table .ant-table-content table > tbody {
      padding-left: 10px !important;
    }
  }
}
