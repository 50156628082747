.root {
  height: calc(100% - 48px);
  :global {
    .search_card {
      height: 80px;
    }
    .search_card .ant-card-body {
      padding: 0px;
      padding-left: 24px;
      padding-top: 4px;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 30px;
    }

    .ant-table-body table {
      padding-left: 10px;
    }

    .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
      padding: 0 !important;
    }
  }
}
